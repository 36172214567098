<template>
  <div id="app">
    <RegisterForm />
  </div>
</template>

<script>
import RegisterForm from './components/RegisterForm.vue';
import 'element-plus/theme-chalk/index.css';

export default {
  name: 'App',
  components: {
    RegisterForm,
  },
};
</script>

<style>
/* 全局样式 */
</style>
