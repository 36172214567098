import axios from 'axios';

const baseUrl = 'https://api.seduno.shop/v1';  // 后端API地址

// 登录获取token
export async function login(password) {
  try {
    const response = await axios.post(`${baseUrl}/token/`, { password });
    return response.data.access_token;
  } catch (error) {
    throw new Error('密码错误');
  }
}

// 注册内容并返回MD5哈希值
export async function register(serialNo, token, flag) {
  try {
    const response = await axios.post(`${baseUrl}/register/`, { serial_no: serialNo, flag: flag }, {
      headers: {
        Authorization: `Bearer ${token}`
      }
    });
    return response.data.data;
  } catch (error) {
    throw new Error('注册失败');
  }
}