<template>
  <div class="container">
    <el-card class="box-card">
      <h1 class="system-title">狮丹努注册系统</h1>
      <div v-if="!authenticated">
        <!-- 添加验证规则 -->
        <el-form :model="loginForm" :rules="loginRules" ref="loginFormRef" @submit.prevent="checkPassword">
          <el-form-item label="请输入访问密码" prop="password">
            <el-input type="password" v-model="loginForm.password" autocomplete="off"></el-input>
          </el-form-item>
          <el-form-item>
            <el-button type="primary" @click="submitLoginForm">提交</el-button>
          </el-form-item>
        </el-form>
      </div>
      <div v-else>
        <!-- 添加验证规则 -->
        <el-form :model="registerForm" :rules="registerRules" ref="registerFormRef" @submit.prevent="submitForm">
          <el-form-item label="选择注册类型" prop="flag">
            <el-select v-model="registerForm.flag" placeholder="请选择">
              <el-option label="面料手持" :value="1"></el-option>
              <el-option label="辅料手持" :value="2"></el-option>
              <el-option label="通用平板" :value="3"></el-option>
              <el-option label="验布程序" :value="4"></el-option>
            </el-select>
          </el-form-item>
          <el-form-item label="请输入内容" prop="serialNo">
            <el-input v-model="registerForm.serialNo" autocomplete="off"></el-input>
          </el-form-item>
          <el-form-item>
            <el-button type="primary" @click="submitRegisterForm">提交</el-button>
          </el-form-item>
        </el-form>
        <el-alert v-if="result" title="" type="success" @close="resetResult">
          <p class="p-info">{{ result }}</p>
          <el-button type="success" @click="copyResult">复制</el-button>
        </el-alert>
      </div>
    </el-card>
  </div>
</template>

<script>
import { ref, onMounted } from "vue";
import jwtDecode from 'jwt-decode';
import {
  ElForm,
  ElFormItem,
  ElInput,
  ElButton,
  ElAlert,
  ElSelect,
  ElOption,
  ElCard,
  ElMessage,
} from "element-plus";
import { login, register } from "../api/apiService";
import useClipboard from 'vue-clipboard3';

export default {
  components: {
    ElForm,
    ElFormItem,
    ElInput,
    ElButton,
    ElAlert,
    ElSelect,
    ElOption,
    ElCard,
  },
  setup() {
    // 定义登录表单和验证规则
    const loginFormRef = ref(null);
    const loginForm = ref({
      password: ""
    });
    const loginRules = {
      password: [
        { required: true, message: '密码不能为空', trigger: 'blur' }
      ]
    };

    // 定义注册表单和验证规则
    const registerFormRef = ref(null);
    const registerForm = ref({
      serialNo: "",
      flag: 1
    });
    const registerRules = {
      serialNo: [
        { required: true, message: '内容不能为空', trigger: 'blur' }
      ],
      flag: [
        { required: true, message: '请选择注册类型', trigger: 'change' }
      ]
    };

    const result = ref("");
    const authenticated = ref(false);
    let token = localStorage.getItem("token");
    const { toClipboard } = useClipboard()

    // 修改 checkPassword 方法
    function checkPassword() {
      login(loginForm.value.password)
        .then((accessToken) => {
          token = accessToken;
          localStorage.setItem("token", accessToken);
          authenticated.value = true;
        })
        .catch(() => {
          ElMessage({
            message: '密码错误！！',
            type: 'error',
          });
        });
    }

    // 提交登录表单
    function submitLoginForm() {
      loginFormRef.value.validate((valid) => {
        if (valid) {
          checkPassword();
        } else {
          console.log('表单验证失败');
          return false;
        }
      });
    }

    // 修改 submitForm 方法
    function submitForm() {
      registerFormRef.value.validate((valid) => {
        if (valid) {
          register(registerForm.value.serialNo, token, registerForm.value.flag)
            .then((hashValue) => {
              result.value = hashValue;
            })
            .catch((error) => {
              ElMessage({
                message: error,
                type: 'error',
              });
            });
        } else {
          console.log('表单验证失败');
          return false;
        }
      });
    }

    function submitRegisterForm() {
      registerFormRef.value.validate((valid) => {
        if (valid) {
          submitForm();
        } else {
          console.log('表单验证失败');
          return false;
        }
      });
    }

    function checkAuthentication() {
      if (token) {
        try {
          const decodedToken = jwtDecode(token);
          const now = Date.now() / 1000;
          if (decodedToken.exp && decodedToken.exp < now) {
            // token已过期，需要重新验证
            authenticated.value = false;
          } else {
            authenticated.value = true;
          }
        } catch (error) {
          console.error('Error decoding token:', error);
          authenticated.value = false;
        }
      } else {
        authenticated.value = false;
      }
    }

    function copyResult() {
      toClipboard(result.value)
        .then(() => {
          ElMessage({
            message: '复制成功',
            type: 'success',
          });
        })
        .catch(() => {
          ElMessage({
            message: '复制失败',
            type: 'error',
          });
        });
    }

    function resetResult() {
      result.value = "";
    }

    onMounted(() => {
      checkAuthentication();
    });

    return {
      loginForm,
      loginRules,
      loginFormRef,
      registerForm,
      registerRules,
      registerFormRef,
      result,
      authenticated,
      checkPassword,
      submitForm,
      submitLoginForm,
      submitRegisterForm,
      copyResult,
      resetResult,
    };
  },
};
</script>

<style scoped>
.container {
  width: 550px;
  margin: 10px auto;
}
.box-card {
  padding: 10px;
}
.system-title {
  text-align: center;
  font-size: 28px;
  color: #409EFF; /* 蓝色 */
  margin-bottom: 20px;
}

.p-info {
  color: #0975e0;
}
</style>
